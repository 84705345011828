import {
  Badge,
  Box,
  ButtonGroup,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { Button } from "gatsby-material-ui-components";
import { t } from "i18next";
import { startupNonProfitDiscount } from "../../../../helpers/clientSide/constants";
import theme from "../../../../layouts/theme";
import { SanitizeHTML } from "../../../01-atoms/SanitizeHTML/SanitzeHTML";
import RVODiscountBadge from "../RVODiscountBadge/RVODiscountBadge";
import { pricingPackages } from "../RVOPricingTemplateData";
import { RVOPricingDiscountButtonsProps } from "./RVOPricingDiscountButtonsInterfaces";

const RVOPricingDiscountButtons = ({
  isShowingStartupDiscount,
  isShowingYearlyDiscount,
  setIsShowingStartupDiscount,
  setIsShowingYearlyDiscount,
}: RVOPricingDiscountButtonsProps): JSX.Element => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleActivateStartupDiscount = () => {
    setIsShowingStartupDiscount(true);
  };

  return (
    <Box marginBottom={4}>
      <Typography
        style={{
          marginBottom: theme.spacing(0.5),
          textAlign: "center",
        }}
      >
        <SanitizeHTML
          html={t("pricing.startupIntroText.text", {
            discount: startupNonProfitDiscount,
          })}
        />
      </Typography>

      <Typography
        style={{
          marginBottom: theme.spacing(2),
          textAlign: "center",
        }}
        variant="body2"
      >
        {t("pricing.startupIntroText.footnote")}
      </Typography>

      <Box
        display="flex"
        flexDirection={isMobile ? "column" : "row"}
        justifyContent="center"
        style={{ gap: theme.spacing(1) }}
      >
        <ButtonGroup
          size="small"
          disableElevation={true}
          fullWidth={isMobile ? true : false}
        >
          <Button
            variant={isShowingStartupDiscount ? "outlined" : "contained"}
            onClick={() => setIsShowingStartupDiscount(false)}
          >
            {t("pricing.discountButtons.startupDiscount.false")}
          </Button>

          <Button
            variant={!isShowingStartupDiscount ? "outlined" : "contained"}
            onClick={() => handleActivateStartupDiscount()}
          >
            {t("pricing.discountButtons.startupDiscount.true")}
          </Button>
        </ButtonGroup>

        <Badge
          badgeContent={
            <Box
              style={{
                transform: "scale(0.7) translate(-20px, 0px)",
                display: isMobile ? "none" : "block",
              }}
            >
              <RVODiscountBadge
                isShowingStartupDiscount={false}
                isShowingYearlyDiscount={true}
                pricingPackage={pricingPackages.Pro}
              />
            </Box>
          }
        >
          <ButtonGroup
            size="small"
            disableElevation={true}
            fullWidth={isMobile ? true : false}
          >
            <Button
              variant={isShowingYearlyDiscount ? "outlined" : "contained"}
              onClick={() => setIsShowingYearlyDiscount(false)}
            >
              {t("pricing.discountButtons.paymentPeriodDiscount.false")}
            </Button>

            <Button
              variant={!isShowingYearlyDiscount ? "outlined" : "contained"}
              onClick={() => setIsShowingYearlyDiscount(true)}
            >
              {t("pricing.discountButtons.paymentPeriodDiscount.true")}
            </Button>
          </ButtonGroup>
        </Badge>
      </Box>
    </Box>
  );
};

export default RVOPricingDiscountButtons;
