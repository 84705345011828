import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { fontWeightBold, fontWeightRegular } from "../../../layouts/theme";

export const useRVOPricingPageStyles = makeStyles((theme: Theme) =>
  createStyles({
    warningButton: {
      backgroundColor: theme.palette.warning.light,
      color: theme.palette.primary.dark,
      "&:hover": {
        backgroundColor: theme.palette.warning.dark,
        color: theme.palette.primary.contrastText,
      },
    },
    pricingPackageCard: {
      height: "100%",
      position: "relative",
      overflow: "visible",
    },
    pricingPackageNotAvailable: {
      pointerEvents: "none",
      filter: "grayscale(100%)",
      opacity: 0.7,
    },
    pricingPackageTitleWrapper: {
      marginBottom: theme.spacing(1),
    },
    pricingPackageTitle: {
      marginBottom: theme.spacing(1),
      fontFamily: "Playfair Display",
      fontWeight: fontWeightBold,
    },
    pricingPackageDescription: {
      minHeight: theme.spacing(8),
    },
    pricingPackagePriceWrapper: {
      minHeight: theme.spacing(8),
      marginBottom: theme.spacing(4),
    },
    pricingPackageExplanation: {
      marginTop: theme.spacing(1),
    },
    pricingPackageButton: {
      marginBottom: theme.spacing(1),
    },
    pricingPackageFeatureList: {
      marginBottom: theme.spacing(1),
    },
    pricingPackageHighlightedText: {
      color: theme.palette.secondary.contrastText,
      fontWeight: fontWeightBold,
      lineHeight: 0,
    },
    pricingPackageNotAvailableText: {
      color: theme.palette.grey[500],
      fontWeight: fontWeightRegular,
      lineHeight: 0,
    },
    pricingPackageHighlightedCard: {
      border: `3px solid ${theme.palette.warning.light}`,
      backgroundColor: theme.palette.warning.light,
      borderRadius: theme.shape.borderRadius,
    },
    pricingPackageHighlightedBox: {
      padding: theme.spacing(2),
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    hide: {
      display: "none !important",
    },
    show: {
      display: "table-row",
    },
  })
);
