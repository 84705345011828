import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { TranslatedString } from "@nvon/react-toolbox";
import { t } from "i18next";
import {
  supportedBoardTypes,
  supportedOrganizationTypes,
} from "../RVOPricingTemplateData";
import { useRVOPricingPageFaqsStyles } from "./RVOPricingPageFaqsStyles";

const RVOPricingPageFaqs = (): JSX.Element => {
  const classes = useRVOPricingPageFaqsStyles();

  interface Faq {
    question: string | TranslatedString;
    answer: string | JSX.Element;
  }

  const pricingFaqs: Faq[] = [
    {
      question: t("pricing.faq.supportedBoardTypes.question"),
      answer: (
        <>
          <Typography variant="h5" component="h3">
            {t("pricing.faq.supportedBoardTypes.answer")}
          </Typography>

          <List>
            {supportedBoardTypes.map((boardType) => (
              <ListItem style={{ padding: 0 }} divider={true} key={boardType}>
                <ListItemText>{boardType}</ListItemText>
              </ListItem>
            ))}
          </List>
        </>
      ),
    },
    {
      question: t("pricing.faq.supportedOrganizationTypes.question"),
      answer: (
        <>
          <Typography variant="h5" component="h3">
            {t("pricing.faq.supportedOrganizationTypes.answer")}
          </Typography>

          <List>
            {supportedOrganizationTypes.map((organizationType) => (
              <ListItem
                style={{ padding: 0 }}
                divider={true}
                key={organizationType}
              >
                <ListItemText>{organizationType}</ListItemText>
              </ListItem>
            ))}
          </List>
        </>
      ),
    },
    {
      question: t("pricing.faq.supportedCountries.question"),
      answer: (
        <List>
          <Typography variant="body1">
            {t("pricing.faq.supportedCountries.answer")}
          </Typography>
        </List>
      ),
    },
  ];

  return (
    <Box>
      {pricingFaqs.map((faq, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Accordion key={i}>
          <AccordionSummary
            expandIcon={<AddIcon className={classes.expandIcon} />}
          >
            <Typography variant="h4" component="h3">
              {faq.question}
            </Typography>
          </AccordionSummary>

          <AccordionDetails>
            <Typography variant="body1">{faq.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default RVOPricingPageFaqs;
