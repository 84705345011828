import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";

export enum PricingButtonVariants {
  Primary = "primary",
  Warning = "warning",
}

export enum PricingPackages {
  Free = "Free",
  Starter = "Starter",
  Pro = "Pro",
  Enterprise = "Enterprise",
}

export const pricingPackageNames: Record<PricingPackages, string> = {
  [PricingPackages.Free]: "Free",
  [PricingPackages.Starter]: "Starter",
  [PricingPackages.Pro]: "Pro",
  [PricingPackages.Enterprise]: "Enterprise",
};

export enum FeatureCategory {
  DigitalerUmlaufbeschuss = "DigitalerUmlaufbeschluss",
  Beschlussarchiv = "Beschlussarchiv",
  Gremienmanagement = "Gremienmanagement",
  PremiumSupport = "PremiumSupport",
  UnterstuetzteGremien = "UnterstuetzteGremien",
  UnterstuetzteRechtsformen = "UnterstuetzteRechtsformen",
}

export const featureCategoryNames: Record<FeatureCategory, string> = {
  [FeatureCategory.DigitalerUmlaufbeschuss]: "Digitaler Umlaufbeschluss",
  [FeatureCategory.Beschlussarchiv]: "Beschlussarchiv (Decision Cloud)",
  [FeatureCategory.Gremienmanagement]: "Gremienmanagement",
  [FeatureCategory.PremiumSupport]: "Premium-Support",
  [FeatureCategory.UnterstuetzteRechtsformen]: "Unterstützte Rechtsformen",
  [FeatureCategory.UnterstuetzteGremien]: "Unterstützte Gremien",
};

export interface PricingPackagesWithAdditionals {
  packageTitle: PricingPackages;
  addition: string;
}

export interface PricingPackageFeature {
  title: string;
  description: string;
  includedInPackages: PricingPackagesWithAdditionals[];
  category: FeatureCategory;
  secondary: boolean;
}

export interface PricingPackage {
  isHighlighted: boolean;
  title: string;
  description: string;
  price: number | JSX.Element;
  priceExplanation: string;
  buttonLabel: string;
  buttonColor?: PricingButtonVariants;
  features: string[];
  package: PricingPackages;
}

export const pricingPackages: Record<PricingPackages, PricingPackage> = {
  [PricingPackages.Free]: {
    title: pricingPackageNames[PricingPackages.Free],
    isHighlighted: false,
    description:
      "Entdecke, wie Resolvio Beschlüsse in deinem Unternehmen transparent und sicher macht. Unbegrenzt kostenlos.",
    price: 0,
    priceExplanation:
      "Kostenlos und somit bestens geeignet für KMUs und Holdings.",
    buttonLabel: "Kostenlos registrieren",
    buttonColor: PricingButtonVariants.Warning,
    features: [
      "6 Beschlüsse / Jahr",
      "2 Gremien",
      "7 Gremienmitglieder",
      "Standard-Beschlussvorlagen",
      "PDF-Export mit Wasserzeichen",
    ],
    package: PricingPackages.Free,
  },
  [PricingPackages.Starter]: {
    title: pricingPackageNames[PricingPackages.Starter],
    isHighlighted: false,
    description: "Mache Resolvio zu deinem Beschlussarchiv!",
    price: 29,
    priceExplanation: "pro Organisation pro Monat. Netto. In Euro.",
    buttonLabel: "Starter buchen",
    buttonColor: PricingButtonVariants.Warning,
    features: [
      "∞ Beschlüsse",
      "4 Gremien",
      "20 Gremienmitglieder",
      "Starter-Beschlussvorlagen",
      "Kleines Wasserzeichen in der Fußzeile des PDF-Exports",
    ],
    package: PricingPackages.Starter,
  },
  [PricingPackages.Pro]: {
    title: pricingPackageNames[PricingPackages.Pro],
    isHighlighted: true,
    description:
      "Stelle die digitale Beschlussfassung professionell und effektiv auf.",
    price: 149,
    priceExplanation: "pro Organisation pro Monat. Netto. In Euro.",
    buttonLabel: "Pro buchen",
    buttonColor: PricingButtonVariants.Warning,
    features: [
      "∞ Beschlüsse",
      "10 Gremien",
      "50 Gremienmitglieder",
      "Exklusive Premium-Beschlussvorlagen",
      "Export ohne Wasserzeichen",
      "Premium-Support",
    ],
    package: PricingPackages.Pro,
  },
  [PricingPackages.Enterprise]: {
    title: pricingPackageNames[PricingPackages.Enterprise],
    isHighlighted: false,
    description:
      "Gerne erstellen wir dir ein individuelles Angebot für die Pläne 'Starter' oder 'Pro'",
    price: <QuestionAnswerIcon style={{ fontSize: 45 }} />,
    priceExplanation: "für komplexere Strukturen.",
    buttonLabel: "Preis anfragen",
    buttonColor: PricingButtonVariants.Warning,
    features: [
      "Individuell auf die Mitgliederanzahl zugeschnittenes Angebot",
      'Wahlweise "Individual Starter" oder "Individual Pro"',
      "Automatisierter Import von Nutzerdaten",
      "Persönlicher Support bei Einführung & Betrieb",
    ],
    package: PricingPackages.Enterprise,
  },
};

export const pricingPackageFeatures: PricingPackageFeature[] = [
  {
    title: "Beschluss erstellen",
    description: "",
    includedInPackages: [
      { packageTitle: PricingPackages.Free, addition: "" },
      { packageTitle: PricingPackages.Starter, addition: "" },
      { packageTitle: PricingPackages.Pro, addition: "" },
    ],
    category: FeatureCategory.DigitalerUmlaufbeschuss,
    secondary: false,
  },
  {
    title: "Vorlagen nutzen",
    description: "",
    includedInPackages: [
      { packageTitle: PricingPackages.Free, addition: "Basis-Vorlagen" },
      { packageTitle: PricingPackages.Starter, addition: "Premium-Vorlagen" },
      { packageTitle: PricingPackages.Pro, addition: "Premium-Vorlagen" },
    ],
    category: FeatureCategory.DigitalerUmlaufbeschuss,
    secondary: false,
  },
  {
    title: "Beschlussvorschläge zur Abstimmung stellen",
    description: "",
    includedInPackages: [
      {
        packageTitle: PricingPackages.Free,
        addition: "100 pro Unternehmen/im Jahr",
      },
      {
        packageTitle: PricingPackages.Starter,
        addition: "1.000 pro Unternehmen/im Jahr",
      },
      { packageTitle: PricingPackages.Pro, addition: "unbegrenzt" },
    ],
    category: FeatureCategory.DigitalerUmlaufbeschuss,
    secondary: false,
  },
  {
    title: "Beschlussanlagen hinzufügen",
    description: "",
    includedInPackages: [
      {
        packageTitle: PricingPackages.Free,
        addition: "alle gängigen Formate (pdf, docx, pptx, etc)",
      },
      {
        packageTitle: PricingPackages.Starter,
        addition: "alle gängigen Formate (pdf, docx, pptx, etc)",
      },
      {
        packageTitle: PricingPackages.Pro,
        addition: "alle gängigen Formate (pdf, docx, pptx, etc)",
      },
    ],
    category: FeatureCategory.DigitalerUmlaufbeschuss,
    secondary: false,
  },
  {
    title:
      "Stimmabgabe mit digitaler Unterschrift (einschließlich Zeitstempel, IP-Adresse)",
    description: "",
    includedInPackages: [
      {
        packageTitle: PricingPackages.Free,
        addition: "",
      },
      {
        packageTitle: PricingPackages.Starter,
        addition: "",
      },
      {
        packageTitle: PricingPackages.Pro,
        addition: "",
      },
    ],
    category: FeatureCategory.DigitalerUmlaufbeschuss,
    secondary: false,
  },
  {
    title: "Beschlussergebnis feststellen und verkünden",
    description: "",
    includedInPackages: [
      { packageTitle: PricingPackages.Free, addition: "" },
      { packageTitle: PricingPackages.Starter, addition: "" },
      { packageTitle: PricingPackages.Pro, addition: "" },
    ],
    category: FeatureCategory.DigitalerUmlaufbeschuss,
    secondary: false,
  },
  {
    title: "Stimmfrist definieren und überwachen",
    description: "",
    includedInPackages: [
      { packageTitle: PricingPackages.Free, addition: "" },
      { packageTitle: PricingPackages.Starter, addition: "" },
      { packageTitle: PricingPackages.Pro, addition: "" },
    ],
    category: FeatureCategory.DigitalerUmlaufbeschuss,
    secondary: false,
  },
  {
    title:
      "Automatische Erinnerung der Gremienmitglieder an Ende der Stimmfrist",
    description: "",
    includedInPackages: [
      { packageTitle: PricingPackages.Free, addition: "" },
      { packageTitle: PricingPackages.Starter, addition: "" },
      { packageTitle: PricingPackages.Pro, addition: "" },
    ],
    category: FeatureCategory.DigitalerUmlaufbeschuss,
    secondary: false,
  },
  {
    title: "Zugriff auf vergangene Beschlüsse",
    description: "",
    includedInPackages: [
      {
        packageTitle: PricingPackages.Free,
        addition: "6 Monate nach Beendigung der Abstimmung",
      },
      { packageTitle: PricingPackages.Starter, addition: "unbegrenzt" },
      { packageTitle: PricingPackages.Pro, addition: "unbegrenzt" },
    ],
    category: FeatureCategory.Beschlussarchiv,
    secondary: false,
  },
  {
    title: "Durchsuchen vergangener Beschlüsse",
    description: "",
    includedInPackages: [
      { packageTitle: PricingPackages.Free, addition: "" },
      { packageTitle: PricingPackages.Starter, addition: "" },
      { packageTitle: PricingPackages.Pro, addition: "" },
    ],
    category: FeatureCategory.Beschlussarchiv,
    secondary: false,
  },
  {
    title: "PDF-Export",
    description: "",
    includedInPackages: [
      {
        packageTitle: PricingPackages.Starter,
        addition: "mit Resolvio-Branding",
      },
      { packageTitle: PricingPackages.Pro, addition: "ohne Resolvio-Branding" },
    ],
    category: FeatureCategory.Beschlussarchiv,
    secondary: false,
  },
  {
    title: "Multi-Gremien-Management mit einem Useraccount",
    description: "",
    includedInPackages: [
      { packageTitle: PricingPackages.Free, addition: "" },
      { packageTitle: PricingPackages.Starter, addition: "" },
      { packageTitle: PricingPackages.Pro, addition: "" },
    ],
    category: FeatureCategory.Gremienmanagement,
    secondary: false,
  },
  {
    title: "Einladung von Gremienmitgliedern per Email",
    description: "",
    includedInPackages: [
      { packageTitle: PricingPackages.Free, addition: "" },
      { packageTitle: PricingPackages.Starter, addition: "" },
      { packageTitle: PricingPackages.Pro, addition: "" },
    ],
    category: FeatureCategory.Gremienmanagement,
    secondary: false,
  },
  {
    title:
      "Beschlussvorbereitung durch verbundene Personen (Assistenz, Abteilungsleiter, Berater, Geschäftsführer, Verwalter etc)",
    description: "",
    includedInPackages: [
      { packageTitle: PricingPackages.Free, addition: "" },
      { packageTitle: PricingPackages.Starter, addition: "" },
      { packageTitle: PricingPackages.Pro, addition: "" },
    ],
    category: FeatureCategory.Gremienmanagement,
    secondary: false,
  },
  {
    title: "Premium-24/7-Support",
    description: "",
    includedInPackages: [{ packageTitle: PricingPackages.Pro, addition: "" }],
    category: FeatureCategory.PremiumSupport,
    secondary: false,
  },

  {
    title: "Aufsichtsrat",
    description: "",
    includedInPackages: [
      { packageTitle: PricingPackages.Free, addition: "" },
      { packageTitle: PricingPackages.Starter, addition: "" },
      { packageTitle: PricingPackages.Pro, addition: "" },
    ],
    category: FeatureCategory.UnterstuetzteGremien,
    secondary: false,
  },
  {
    title: "Beirat",
    description: "",
    includedInPackages: [
      { packageTitle: PricingPackages.Free, addition: "" },
      { packageTitle: PricingPackages.Starter, addition: "" },
      { packageTitle: PricingPackages.Pro, addition: "" },
    ],
    category: FeatureCategory.UnterstuetzteGremien,
    secondary: false,
  },
  {
    title: "Erben",
    description: "",
    includedInPackages: [
      { packageTitle: PricingPackages.Free, addition: "" },
      { packageTitle: PricingPackages.Starter, addition: "" },
      { packageTitle: PricingPackages.Pro, addition: "" },
    ],
    category: FeatureCategory.UnterstuetzteGremien,
    secondary: false,
  },

  {
    title: "Genossen",
    description: "",
    includedInPackages: [
      { packageTitle: PricingPackages.Free, addition: "" },
      { packageTitle: PricingPackages.Starter, addition: "" },
      { packageTitle: PricingPackages.Pro, addition: "" },
    ],
    category: FeatureCategory.UnterstuetzteGremien,
    secondary: false,
  },
  {
    title: "Geschäftsführer",
    description: "",
    includedInPackages: [
      { packageTitle: PricingPackages.Free, addition: "" },
      { packageTitle: PricingPackages.Starter, addition: "" },
      { packageTitle: PricingPackages.Pro, addition: "" },
    ],
    category: FeatureCategory.UnterstuetzteGremien,
    secondary: false,
  },
  {
    title: "Gesellschafter",
    description: "",
    includedInPackages: [
      { packageTitle: PricingPackages.Free, addition: "" },
      { packageTitle: PricingPackages.Starter, addition: "" },
      { packageTitle: PricingPackages.Pro, addition: "" },
    ],
    category: FeatureCategory.UnterstuetzteGremien,
    secondary: false,
  },

  {
    title: "Kommanditisten",
    description: "",
    includedInPackages: [
      { packageTitle: PricingPackages.Free, addition: "" },
      { packageTitle: PricingPackages.Starter, addition: "" },
      { packageTitle: PricingPackages.Pro, addition: "" },
    ],
    category: FeatureCategory.UnterstuetzteGremien,
    secondary: false,
  },
  {
    title: "Kuratorium",
    description: "",
    includedInPackages: [
      { packageTitle: PricingPackages.Free, addition: "" },
      { packageTitle: PricingPackages.Starter, addition: "" },
      { packageTitle: PricingPackages.Pro, addition: "" },
    ],
    category: FeatureCategory.UnterstuetzteGremien,
    secondary: false,
  },
  {
    title: "Vereinsmitglieder",
    description: "",
    includedInPackages: [
      { packageTitle: PricingPackages.Free, addition: "" },
      { packageTitle: PricingPackages.Starter, addition: "" },
      { packageTitle: PricingPackages.Pro, addition: "" },
    ],
    category: FeatureCategory.UnterstuetzteGremien,
    secondary: false,
  },
  {
    title: "Verwaltungsrat",
    description: "",
    includedInPackages: [
      { packageTitle: PricingPackages.Free, addition: "" },
      { packageTitle: PricingPackages.Starter, addition: "" },
      { packageTitle: PricingPackages.Pro, addition: "" },
    ],
    category: FeatureCategory.UnterstuetzteGremien,
    secondary: false,
  },
  {
    title: "Vorstand",
    description: "",
    includedInPackages: [
      { packageTitle: PricingPackages.Free, addition: "" },
      { packageTitle: PricingPackages.Starter, addition: "" },
      { packageTitle: PricingPackages.Pro, addition: "" },
    ],
    category: FeatureCategory.UnterstuetzteGremien,
    secondary: false,
  },
  {
    title: "Wohnungseigentümer",
    description: "",
    includedInPackages: [
      { packageTitle: PricingPackages.Free, addition: "" },
      { packageTitle: PricingPackages.Starter, addition: "" },
      { packageTitle: PricingPackages.Pro, addition: "" },
    ],
    category: FeatureCategory.UnterstuetzteGremien,
    secondary: false,
  },
  {
    title: "Benutzerdefiniertes Gremium",
    description: "",
    includedInPackages: [
      { packageTitle: PricingPackages.Free, addition: "" },
      { packageTitle: PricingPackages.Starter, addition: "" },
      { packageTitle: PricingPackages.Pro, addition: "" },
    ],
    category: FeatureCategory.UnterstuetzteGremien,
    secondary: false,
  },

  {
    title: "AG (Aktiengesellschaft)",
    description: "",
    includedInPackages: [
      { packageTitle: PricingPackages.Free, addition: "" },
      { packageTitle: PricingPackages.Starter, addition: "" },
      { packageTitle: PricingPackages.Pro, addition: "" },
    ],
    category: FeatureCategory.UnterstuetzteRechtsformen,
    secondary: false,
  },
  {
    title: "AöR (Anstalt des öffentlichen Rechts)",
    description: "",
    includedInPackages: [
      { packageTitle: PricingPackages.Free, addition: "" },
      { packageTitle: PricingPackages.Starter, addition: "" },
      { packageTitle: PricingPackages.Pro, addition: "" },
    ],
    category: FeatureCategory.UnterstuetzteRechtsformen,
    secondary: false,
  },
  {
    title: "Bruchteilsgemeinschaft",
    description: "",
    includedInPackages: [
      { packageTitle: PricingPackages.Free, addition: "" },
      { packageTitle: PricingPackages.Starter, addition: "" },
      { packageTitle: PricingPackages.Pro, addition: "" },
    ],
    category: FeatureCategory.UnterstuetzteRechtsformen,
    secondary: false,
  },
  {
    title: "e.G. (eingetragene Genossenschaft)",
    description: "",
    includedInPackages: [
      { packageTitle: PricingPackages.Free, addition: "" },
      { packageTitle: PricingPackages.Starter, addition: "" },
      { packageTitle: PricingPackages.Pro, addition: "" },
    ],
    category: FeatureCategory.UnterstuetzteRechtsformen,
    secondary: false,
  },
  {
    title: "Erbengemeinschaft",
    description: "",
    includedInPackages: [
      { packageTitle: PricingPackages.Free, addition: "" },
      { packageTitle: PricingPackages.Starter, addition: "" },
      { packageTitle: PricingPackages.Pro, addition: "" },
    ],
    category: FeatureCategory.UnterstuetzteRechtsformen,
    secondary: false,
  },
  {
    title: "e.V. (eingetragener Verein)",
    description: "",
    includedInPackages: [
      { packageTitle: PricingPackages.Free, addition: "" },
      { packageTitle: PricingPackages.Starter, addition: "" },
      { packageTitle: PricingPackages.Pro, addition: "" },
    ],
    category: FeatureCategory.UnterstuetzteRechtsformen,
    secondary: false,
  },
  {
    title: "EWIV (Europäische wirtschaftliche Interessenvereinigung)",
    description: "",
    includedInPackages: [
      { packageTitle: PricingPackages.Free, addition: "" },
      { packageTitle: PricingPackages.Starter, addition: "" },
      { packageTitle: PricingPackages.Pro, addition: "" },
    ],
    category: FeatureCategory.UnterstuetzteRechtsformen,
    secondary: false,
  },
  {
    title: "GbR (Gesellschaft des bürgerlichen Rechts)",
    description: "",
    includedInPackages: [
      { packageTitle: PricingPackages.Free, addition: "" },
      { packageTitle: PricingPackages.Starter, addition: "" },
      { packageTitle: PricingPackages.Pro, addition: "" },
    ],
    category: FeatureCategory.UnterstuetzteRechtsformen,
    secondary: false,
  },
  {
    title: "GmbH",
    description: "",
    includedInPackages: [
      { packageTitle: PricingPackages.Free, addition: "" },
      { packageTitle: PricingPackages.Starter, addition: "" },
      { packageTitle: PricingPackages.Pro, addition: "" },
    ],
    category: FeatureCategory.UnterstuetzteRechtsformen,
    secondary: false,
  },
  {
    title: "GmbH & Co. KG",
    description: "",
    includedInPackages: [
      { packageTitle: PricingPackages.Free, addition: "" },
      { packageTitle: PricingPackages.Starter, addition: "" },
      { packageTitle: PricingPackages.Pro, addition: "" },
    ],
    category: FeatureCategory.UnterstuetzteRechtsformen,
    secondary: false,
  },
  {
    title: "KG (Kommanditgesellschaft)",
    description: "",
    includedInPackages: [
      { packageTitle: PricingPackages.Free, addition: "" },
      { packageTitle: PricingPackages.Starter, addition: "" },
      { packageTitle: PricingPackages.Pro, addition: "" },
    ],
    category: FeatureCategory.UnterstuetzteRechtsformen,
    secondary: false,
  },
  {
    title: "KGaA (Kommanditgesellschaft auf Aktien)",
    description: "",
    includedInPackages: [
      { packageTitle: PricingPackages.Free, addition: "" },
      { packageTitle: PricingPackages.Starter, addition: "" },
      { packageTitle: PricingPackages.Pro, addition: "" },
    ],
    category: FeatureCategory.UnterstuetzteRechtsformen,
    secondary: false,
  },
  {
    title: "neV (nicht eingetragener Verein)",
    description: "",
    includedInPackages: [
      { packageTitle: PricingPackages.Free, addition: "" },
      { packageTitle: PricingPackages.Starter, addition: "" },
      { packageTitle: PricingPackages.Pro, addition: "" },
    ],
    category: FeatureCategory.UnterstuetzteRechtsformen,
    secondary: false,
  },
  {
    title: "oHG (offene Handelsgesellschaft)",
    description: "",
    includedInPackages: [
      { packageTitle: PricingPackages.Free, addition: "" },
      { packageTitle: PricingPackages.Starter, addition: "" },
      { packageTitle: PricingPackages.Pro, addition: "" },
    ],
    category: FeatureCategory.UnterstuetzteRechtsformen,
    secondary: false,
  },
  {
    title: "PartG (Partnerschaft)",
    description: "",
    includedInPackages: [
      { packageTitle: PricingPackages.Free, addition: "" },
      { packageTitle: PricingPackages.Starter, addition: "" },
      { packageTitle: PricingPackages.Pro, addition: "" },
    ],
    category: FeatureCategory.UnterstuetzteRechtsformen,
    secondary: false,
  },
  {
    title: "PartGmbB (Partnerschaft mit beschränkter Berufshaftung)",
    description: "",
    includedInPackages: [
      { packageTitle: PricingPackages.Free, addition: "" },
      { packageTitle: PricingPackages.Starter, addition: "" },
      { packageTitle: PricingPackages.Pro, addition: "" },
    ],
    category: FeatureCategory.UnterstuetzteRechtsformen,
    secondary: false,
  },

  {
    title: "SCE (Societas Cooperativa Europaea, Europäische Genossenschaft)",
    description: "",
    includedInPackages: [
      { packageTitle: PricingPackages.Free, addition: "" },
      { packageTitle: PricingPackages.Starter, addition: "" },
      { packageTitle: PricingPackages.Pro, addition: "" },
    ],
    category: FeatureCategory.UnterstuetzteRechtsformen,
    secondary: false,
  },
  {
    title: "SE (Societas Europae, Europäische Aktiengesellschaft)",
    description: "",
    includedInPackages: [
      { packageTitle: PricingPackages.Free, addition: "" },
      { packageTitle: PricingPackages.Starter, addition: "" },
      { packageTitle: PricingPackages.Pro, addition: "" },
    ],
    category: FeatureCategory.UnterstuetzteRechtsformen,
    secondary: false,
  },
  {
    title: "Stiftung (Rechtsfähige Stiftung)",
    description: "",
    includedInPackages: [
      { packageTitle: PricingPackages.Free, addition: "" },
      { packageTitle: PricingPackages.Starter, addition: "" },
      { packageTitle: PricingPackages.Pro, addition: "" },
    ],
    category: FeatureCategory.UnterstuetzteRechtsformen,
    secondary: false,
  },

  {
    title: "UG (Unternehmergesellschaft haftungsbeschränkt)",
    description: "",
    includedInPackages: [
      { packageTitle: PricingPackages.Free, addition: "" },
      { packageTitle: PricingPackages.Starter, addition: "" },
      { packageTitle: PricingPackages.Pro, addition: "" },
    ],
    category: FeatureCategory.UnterstuetzteRechtsformen,
    secondary: false,
  },
  {
    title: "UG & Co. KG",
    description: "",
    includedInPackages: [
      { packageTitle: PricingPackages.Free, addition: "" },
      { packageTitle: PricingPackages.Starter, addition: "" },
      { packageTitle: PricingPackages.Pro, addition: "" },
    ],
    category: FeatureCategory.UnterstuetzteRechtsformen,
    secondary: false,
  },
  {
    title: "VVaG (Versicherungsverein auf Gegenseitigkeit)",
    description: "",
    includedInPackages: [
      { packageTitle: PricingPackages.Free, addition: "" },
      { packageTitle: PricingPackages.Starter, addition: "" },
      { packageTitle: PricingPackages.Pro, addition: "" },
    ],
    category: FeatureCategory.UnterstuetzteRechtsformen,
    secondary: false,
  },
  {
    title: "WEG (Wohnungseigentümergemeinschaft)",
    description: "",
    includedInPackages: [
      { packageTitle: PricingPackages.Free, addition: "" },
      { packageTitle: PricingPackages.Starter, addition: "" },
      { packageTitle: PricingPackages.Pro, addition: "" },
    ],
    category: FeatureCategory.UnterstuetzteRechtsformen,
    secondary: false,
  },
  {
    title: "Benutzerdefinierte Organisationsform",
    description: "",
    includedInPackages: [
      { packageTitle: PricingPackages.Free, addition: "" },
      { packageTitle: PricingPackages.Starter, addition: "" },
      { packageTitle: PricingPackages.Pro, addition: "" },
    ],
    category: FeatureCategory.UnterstuetzteRechtsformen,
    secondary: false,
  },
];

export const supportedBoardTypes = [
  "Aufsichtsrat",
  "Beirat",
  "Erben",
  "Genossen",
  "Geschäftsführer",
  "Gesellschafter",
  "Kommanditisten",
  "Kuratorium",
  "Vereinsmitglieder",
  "Verwaltungsrat",
  "Vorstand",
  "Wohnungseigentümer",
  "Benutzerdefiniertes Gremium",
];

export const supportedOrganizationTypes = [
  "AG (Aktiengesellschaft)",
  "AöR (Anstalt des öffentlichen Rechts)",
  "Bruchteilsgemeinschaft",
  "e.G. (eingetragene Genossenschaft)",
  "Erbengemeinschaft",
  "e.V. (eingetragener Verein)",
  "EWIV (Europäische wirtschaftliche Interessenvereinigung)",
  "GbR (Gesellschaft des bürgerlichen Rechts)",
  "GmbH",
  "GmbH & Co. KG",
  "KG (Kommanditgesellschaft)",
  "KGaA (Kommanditgesellschaft auf Aktien)",
  "neV (nicht eingetragener Verein)",
  "oHG (offene Handelsgesellschaft)",
  "PartG (Partnerschaft)",
  "PartGmbB (Partnerschaft mit beschränkter Berufshaftung)",
  "SCE (Societas Cooperativa Europaea, Europäische Genossenschaft)",
  "SE (Societas Europae, Europäische Aktiengesellschaft)",
  "Stiftung (Rechtsfähige Stiftung)",
  "UG (Unternehmergesellschaft haftungsbeschränkt)",
  "UG & Co. KG",
  "VVaG (Versicherungsverein auf Gegenseitigkeit)",
  "WEG (Wohnungseigentümergemeinschaft)",
  "Benutzerdefinierte Organisationsform",
];
