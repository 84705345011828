import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { useBooleanState } from "@nvon/react-toolbox";
import { clsx } from "clsx";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import {
  resolvioAppPurchasePackageUrl,
  resolvioAppRegisterUrl,
} from "../../../helpers/clientSide/constants";
import RVOContainer from "../../02-molecules/RVOContainer/RVOContainer";
import { RVOBackgroundGradient } from "../../02-molecules/RVOContainer/RVOContainerInterfaces";
import {
  BackgroundColors,
  HeadlineComponent,
} from "../../03-organisms/RVOFeaturedTextSection/RVOFeaturedTextInterfaces";
import RVOFeaturedTextSection from "../../03-organisms/RVOFeaturedTextSection/RVOFeaturedTextSection";
import RVORegisterTodayCtaSection from "../../03-organisms/RVORegisterTodayCtaSection/RVORegisterTodayCtaSection";
import RVODiscountBadge from "./RVODiscountBadge/RVODiscountBadge";
import RVOPricingCalculation from "./RVOPricingCalculation/RVOPricingCalculation";
import RVOPricingDiscountButtons from "./RVOPricingDiscountButtons/RVOPricingDiscountButtons";
import RVOPricingPageFaqs from "./RVOPricingPageFaqs/RVOPricingPageFaqs";
import {
  PricingButtonVariants,
  PricingPackages,
  pricingPackages,
} from "./RVOPricingTemplateData";
import { useRVOPricingPageStyles } from "./RVOPricingTemplateStyles";

const RVOPricingTemplate = (): JSX.Element => {
  const { t } = useTranslation();
  const classes = useRVOPricingPageStyles();

  const { state: isShowingStartupDiscount, set: setIsShowingStartupDiscount } =
    useBooleanState(false);

  const { state: isShowingYearlyDiscount, set: setIsShowingYearlyDiscount } =
    useBooleanState(false);

  const mapPackageToHref: Record<PricingPackages, string> = {
    [PricingPackages.Free]: resolvioAppRegisterUrl,
    [PricingPackages.Pro]: resolvioAppPurchasePackageUrl,
    [PricingPackages.Starter]: resolvioAppPurchasePackageUrl,
    [PricingPackages.Enterprise]: "mailto:enterprise@resolvio.com",
  };

  const sanitzedTitle = t("pricing.pricingPageName").replace(
    /<\/?[^>]+(>|$)/g,
    ""
  );

  return (
    <>
      <Helmet>
        <title>{sanitzedTitle}</title>

        <meta name="description" content={t("pricing.pretitle")} />
      </Helmet>

      <RVOFeaturedTextSection
        pretext={t("pricing.pretitle")}
        backgroundColored={BackgroundColors.BlueLight}
        headlineCentered={true}
        wide={true}
        pretextComponent={HeadlineComponent.H1}
        xxlHeadline={true}
        headline={t("pricing.pricingPageName")}
      >
        <Container maxWidth="md" style={{ marginLeft: "auto" }}>
          <RVOPricingDiscountButtons
            isShowingStartupDiscount={isShowingStartupDiscount}
            isShowingYearlyDiscount={isShowingYearlyDiscount}
            setIsShowingStartupDiscount={setIsShowingStartupDiscount}
            setIsShowingYearlyDiscount={setIsShowingYearlyDiscount}
          />

          <Grid container={true} spacing={2}>
            {Object.values(pricingPackages).map((pricingPackage) => (
              <Grid
                key={pricingPackage.title}
                item={true}
                xs={12}
                md={6}
                lg={3}
              >
                <Box
                  className={clsx(
                    pricingPackage.isHighlighted
                      ? classes.pricingPackageHighlightedCard
                      : undefined
                  )}
                >
                  <Box className={classes.pricingPackageHighlightedBox}>
                    <Typography
                      className={clsx(
                        pricingPackage.isHighlighted
                          ? classes.show
                          : classes.hide,
                        classes.pricingPackageHighlightedText
                      )}
                    >
                      {t("pricing.recommended")}
                    </Typography>
                  </Box>

                  <Card className={classes.pricingPackageCard}>
                    <RVODiscountBadge
                      isShowingStartupDiscount={isShowingStartupDiscount}
                      isShowingYearlyDiscount={isShowingYearlyDiscount}
                      pricingPackage={pricingPackage}
                    />

                    <CardContent>
                      <Box
                        marginBottom={1}
                        className={classes.pricingPackageTitleWrapper}
                      >
                        <Typography
                          className={classes.pricingPackageTitle}
                          variant="h3"
                          component="h2"
                        >
                          {pricingPackage.title}
                        </Typography>

                        <Typography
                          variant="body1"
                          className={classes.pricingPackageDescription}
                        >
                          {pricingPackage.description}
                        </Typography>
                      </Box>

                      <Box marginBottom={1} textAlign="center">
                        <Box
                          marginBottom={1}
                          className={classes.pricingPackagePriceWrapper}
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Box>
                            {typeof pricingPackage.price === "number" ? (
                              <RVOPricingCalculation
                                isShowingStartupDiscount={
                                  isShowingStartupDiscount
                                }
                                isShowingYearlyDiscount={
                                  isShowingYearlyDiscount
                                }
                                pricingPackage={pricingPackage}
                                round={false}
                              />
                            ) : (
                              pricingPackage.price
                            )}
                          </Box>

                          <Typography
                            className={classes.pricingPackageExplanation}
                            variant="body2"
                            component="p"
                          >
                            {pricingPackage.priceExplanation}
                          </Typography>
                        </Box>

                        <Link
                          href={mapPackageToHref[pricingPackage.package]}
                          target="_blank"
                        >
                          <Button
                            variant="contained"
                            className={clsx(
                              pricingPackage.buttonColor ===
                                PricingButtonVariants.Warning
                                ? classes.warningButton
                                : undefined,
                              classes.pricingPackageButton
                            )}
                            fullWidth={true}
                          >
                            {pricingPackage.buttonLabel}
                          </Button>
                        </Link>

                        <List className={classes.pricingPackageFeatureList}>
                          {pricingPackage.features.map((feature) => (
                            <ListItem
                              alignItems="flex-start"
                              disableGutters={true}
                              key={feature}
                            >
                              <ListItemIcon>
                                {<CheckCircleIcon color="primary" />}
                              </ListItemIcon>

                              <ListItemText>{feature}</ListItemText>
                            </ListItem>
                          ))}
                        </List>
                      </Box>
                    </CardContent>
                  </Card>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>
      </RVOFeaturedTextSection>

      <RVOContainer
        background={RVOBackgroundGradient.SecondaryLightToMediumBlue}
        noPaddingTop={true}
        paddingBottom={true}
      >
        <RVOPricingPageFaqs />
      </RVOContainer>

      <RVORegisterTodayCtaSection />
    </>
  );
};

export default RVOPricingTemplate;
