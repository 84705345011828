import { Box, Chip } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { startupNonProfitDiscount } from "../../../../helpers/clientSide/constants";
import { PricingPackage } from "../RVOPricingTemplateData";
import { useRVODiscountBadgeStyles } from "./RVODiscountBadgeStyles";

export interface RVODiscountBadgeProps {
  isShowingStartupDiscount: boolean;
  isShowingYearlyDiscount: boolean;
  pricingPackage: PricingPackage;
}

const RVODiscountBadge = ({
  isShowingStartupDiscount,
  isShowingYearlyDiscount,
  pricingPackage: { price },
}: RVODiscountBadgeProps): JSX.Element | null => {
  const classes = useRVODiscountBadgeStyles();
  const { t } = useTranslation();

  const getBadgeContent = (
    startupDiscount: boolean,
    yearlyDiscount: boolean
  ) => {
    if (startupDiscount && yearlyDiscount) {
      return t("pricing.discountBadge.bothDiscounts", {
        discount: startupNonProfitDiscount,
      });
    }

    if (startupDiscount) {
      return t("pricing.discountBadge.onlyStartupDiscount", {
        discount: startupNonProfitDiscount,
      });
    }

    if (yearlyDiscount) {
      return t("pricing.discountBadge.onlyYearlyDiscount");
    }

    return "";
  };

  const hasPrice = typeof price === "number" && price;
  const hasDiscount = isShowingStartupDiscount || isShowingYearlyDiscount;
  const show = hasDiscount && hasPrice;

  return show ? (
    <Box>
      <Box className={classes.discountBadgeWrapper}>
        <Chip
          label={getBadgeContent(
            isShowingStartupDiscount,
            isShowingYearlyDiscount
          )}
          className={classes.discountBadge}
        />
      </Box>
    </Box>
  ) : null;
};

export default RVODiscountBadge;
