import { createStyles, makeStyles } from "@material-ui/core";
import theme from "../../../../layouts/theme";

export const useRVOPricingPageFaqsStyles = makeStyles(() =>
  createStyles({
    expandIcon: {
      color: theme.palette.primary.dark,
      fontSize: theme.spacing(2),
      backgroundColor: theme.palette.background.default,
      borderRadius: "50%",
      width: theme.spacing(2.5),
      height: theme.spacing(2.5),
      padding: theme.spacing(0.5),
    },
  })
);
